import { types as api } from '@mesa-labs/mesa-api';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';
import { Card, CardContainer, CardTitle, LoadingSpinner, snakeCaseToProperCase, Table, Typography } from '@mesa-labs/mesa-ui';
import { DateTime, DateTimeUnit } from 'luxon';

import {
  barChartOptions,
  COLOR_CLIENT,
  COLOR_VENDOR,
  CurrencyFormatter,
  enumEntryToSelection,
  findSelectionByValueOrDefault,
  IdentityFormatter,
  lineChartOptions,
  runningStatisticToCumulativeXyData,
  runningStatisticToInterPeriodGrowthXyData,
  toFilterEnum,
  useTimezoneSelection,
} from '../../utils';
import { FilterCell, FilterCheckbox, FilterRow, FilterSection, FilterTitle, } from '../common/Filters';
import {
  useGetAcceleratedPayoutLookaheadBucketsQuery,
  useGetAcceleratedPayoutStatisticsQuery,
} from '../../redux/api/invoices';
import Select from '../common/Select';
import PartnerSelect from '../common/PartnerSelect';
import { useDispatch, useSelector } from '../../redux/hooks';
import VendorLocaleSelect from '../common/VendorLocaleSelect';
import {
  updateAcceleratedPayoutMethod,
  updateBeginningAt,
  updateEndingAt,
  updateIncludePreviousTotals,
  updatePartner,
  updatePeriodization,
  updateVendorLocale,
  initialState as AcceleratedPayoutInitialState,
  updateEnforcePeriodization, updateDateAnchoring
} from '../../redux/slices/acceleratedPayout';
import useSyncQueryStringParamsToRedux from '../../hooks/useSyncQueryStringParamsToRedux';
import DateRangeFilter from '../common/DateRangeFilter';

const Label = styled(Typography.BodySmallBold)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

const StatisticsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PeriodizationSelections = Object.entries(api.TimeSeriesPeriodization).filter(([_k, v]) => v !== api.TimeSeriesPeriodization.CENTURY)
  .map((x) => enumEntryToSelection(snakeCaseToProperCase(x[0]), x[1]));

const AcceleratedPayoutMethodSelections = [{ label: 'All', value: undefined }, ...toFilterEnum(api.AcceleratedPayoutMethod)];
const AcceleratedPayoutDateAnchoringSelections = [{ label: 'All', value: undefined }, ...toFilterEnum(api.AcceleratedPayoutStatisticsDateAnchoring)];

function AcceleratedPayoutStatisticsPage(): React.ReactElement | null {
  const dispatch = useDispatch();
  const partner = useSelector((state) => state.acceleratedPayout.partner);
  const vendorLocale = useSelector((state) => state.acceleratedPayout.vendorLocale);
  const acceleratedPayoutMethod = useSelector((state) => state.acceleratedPayout.acceleratedPayoutMethod);
  const dateAnchoring = useSelector((state) => state.acceleratedPayout.dateAnchoring);
  const periodization = useSelector((state) => state.acceleratedPayout.periodization) || AcceleratedPayoutInitialState.periodization;
  const enforcePeriodization = useSelector((state) => state.acceleratedPayout.enforcePeriodization) || AcceleratedPayoutInitialState.enforcePeriodization;
  const includePreviousTotals = useSelector((state) => state.acceleratedPayout.includePreviousTotals);
  const beginningAt = useSelector((state) => state.acceleratedPayout.beginningAt) || AcceleratedPayoutInitialState.beginningAt;
  const endingAt = useSelector((state) => state.acceleratedPayout.endingAt) || AcceleratedPayoutInitialState.endingAt;

  useSyncQueryStringParamsToRedux({ sliceName: 'acceleratedPayout' });

  const statisticsRequestData = {
    beginningAt,
    endingAt,
    periodization,
    includePreviousSum: (includePreviousTotals ? 'true' : 'false') as api.BooleanString,
    partnerId: partner,
    vendorLocale,
    acceleratedPayoutMethod,
    dateAnchoring
  };

  const {
    data: acceleratedPayoutData,
    isFetching: isAcceleratedPayoutDataFetching,
  } = useGetAcceleratedPayoutStatisticsQuery(statisticsRequestData);

  const {
    data: acceleratedPayoutLookaheadBuckets,
  } = useGetAcceleratedPayoutLookaheadBucketsQuery({});

  const invoiceCountDataSeries: ApexAxisChartSeries = useMemo(() => {
    if (!acceleratedPayoutData) {
      return [];
    }

    return [
      {
        name: 'Invoices Requested',
        data: runningStatisticToCumulativeXyData(acceleratedPayoutData.invoiceCounts),
      },
    ];
  }, [acceleratedPayoutData]);

  const periodicInvoiceCountDataSeries: ApexAxisChartSeries = useMemo(() => {
    if (!acceleratedPayoutData) {
      return [];
    }

    return [
      {
        name: 'Invoices Requested (Periodic)',
        data: runningStatisticToInterPeriodGrowthXyData(acceleratedPayoutData.invoiceCounts),
      },
    ];
  }, [acceleratedPayoutData]);

  const revenueLiftDataSeries: ApexAxisChartSeries = useMemo(() => {
    if (!acceleratedPayoutData) {
      return [];
    }

    return [
      {
        name: 'Revenue Lift',
        data: runningStatisticToCumulativeXyData(acceleratedPayoutData.revenueLiftSum)
      },
    ];
  }, [acceleratedPayoutData]);

  const periodicRevenueLiftDataSeries: ApexAxisChartSeries = useMemo(() => {
    if (!acceleratedPayoutData) {
      return [];
    }

    return [
      {
        name: 'Invoices Revenue Lift (Periodic)',
        data: runningStatisticToInterPeriodGrowthXyData(acceleratedPayoutData.revenueLiftSum)
      },
    ];
  }, [acceleratedPayoutData]);

  const openAmountDataSeries: ApexAxisChartSeries = useMemo(() => {
    if (!acceleratedPayoutData) {
      return [];
    }

    return [
      {
        name: 'Amount',
        data: runningStatisticToCumulativeXyData(acceleratedPayoutData.adjustedOpenAmountSum)
      },
    ];
  }, [acceleratedPayoutData]);

  const periodicOpenAmountDataSeries: ApexAxisChartSeries = useMemo(() => {
    if (!acceleratedPayoutData) {
      return [];
    }

    return [
      {
        name: 'Amount (Periodic)',
        data: runningStatisticToInterPeriodGrowthXyData(acceleratedPayoutData.adjustedOpenAmountSum)
      },
    ];
  }, [acceleratedPayoutData]);

  return (
    <StatisticsPageContainer>
      <FilterSection
        filterHeader="Filters"
      >
        <FilterRow>
          <FilterCell>
            <FilterTitle>Partner</FilterTitle>
            <PartnerSelect
              selectedPartner={partner}
              onSelectedPartnerChange={(p) => dispatch(updatePartner(p))}
              allowAllPartners
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Vendor Locale</FilterTitle>
            <VendorLocaleSelect
              selectedVendorLocale={vendorLocale}
              onSelectedVendorLocaleChange={(vl) => dispatch(updateVendorLocale(vl))}
              allowAllVendorLocales
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Accelerated Payout Method</FilterTitle>
            <Select
              value={findSelectionByValueOrDefault(acceleratedPayoutMethod, AcceleratedPayoutMethodSelections)}
              onChange={(selection) => dispatch(updateAcceleratedPayoutMethod(selection.value!))}
              options={AcceleratedPayoutMethodSelections}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Date Anchoring</FilterTitle>
            <Select
              value={findSelectionByValueOrDefault(dateAnchoring, AcceleratedPayoutDateAnchoringSelections)}
              onChange={(selection) => dispatch(updateDateAnchoring(selection.value!))}
              options={AcceleratedPayoutDateAnchoringSelections}
            />
          </FilterCell>
        </FilterRow>
        <FilterRow>
          <FilterCell>
            <FilterTitle>Periodization</FilterTitle>
            <Select
              value={findSelectionByValueOrDefault(periodization, PeriodizationSelections)}
              onChange={(selection) => dispatch(updatePeriodization(selection.value!))}
              options={PeriodizationSelections}
            />
          </FilterCell>
          <FilterCell>
            <FilterCheckbox
              label="Include previous totals?"
              onChange={() => dispatch(updateIncludePreviousTotals(!includePreviousTotals))}
              checked={includePreviousTotals !== undefined ? includePreviousTotals : false}
            />
          </FilterCell>
          <FilterCell>
            <DateRangeFilter
              beginningAt={DateTime.fromISO(beginningAt)}
              onBeginningAtChange={(value) => dispatch(updateBeginningAt(useTimezoneSelection(value.startOf('day')).toISO()))}
              endingAt={DateTime.fromISO(endingAt)}
              onEndingAtChange={(value) => dispatch(updateEndingAt(useTimezoneSelection(value.endOf('day')).toISO()))}
              periodization={enforcePeriodization ? periodization as DateTimeUnit : undefined}
            />
          </FilterCell>
          <FilterCell>
            <FilterCheckbox
              label="Enforce periodization on date range?"
              onChange={() => dispatch(updateEnforcePeriodization(!enforcePeriodization))}
              checked={enforcePeriodization}
            />
          </FilterCell>
        </FilterRow>
      </FilterSection>
      <Label>Bucket Status</Label>
      <Table
        columns={[
          {
            key: 'aggregate',
            label: 'Consumed Amount',
            type: 'currency'
          },
          {
            key: 'maximum',
            label: 'Max Bucket Amount',
            type: 'currency'
          },
          {
            key: 'periodStart',
            label: 'Cutoff Start',
            type: 'datetime'
          },
          {
            key: 'periodEnd',
            label: 'Cutoff End',
            type: 'datetime'
          },
        ]}
        rows={acceleratedPayoutLookaheadBuckets?.lookaheadBuckets.map((x) => ({ ...x, id: x.periodStart, currencyCode: 'USD' })) || []}
      />
      <Label>Statistics</Label>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Invoice Request Counts</CardTitle>
          {(!isAcceleratedPayoutDataFetching && (
            <ReactApexChart options={lineChartOptions(IdentityFormatter, [COLOR_VENDOR])} series={invoiceCountDataSeries} type="line" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
        <Card minHeight="400px">
          <CardTitle>Invoice Request Counts (Periodic)</CardTitle>
          {(!isAcceleratedPayoutDataFetching && (
            <ReactApexChart options={barChartOptions(IdentityFormatter, [COLOR_VENDOR])} series={periodicInvoiceCountDataSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
      </CardContainer>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Revenue Lift</CardTitle>
          {(!isAcceleratedPayoutDataFetching && (
            <ReactApexChart options={lineChartOptions(CurrencyFormatter, [COLOR_CLIENT])} series={revenueLiftDataSeries} type="line" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
        <Card minHeight="400px">
          <CardTitle>Revenue Lift (Periodic)</CardTitle>
          {(!isAcceleratedPayoutDataFetching && (
            <ReactApexChart options={barChartOptions(CurrencyFormatter, [COLOR_CLIENT])} series={periodicRevenueLiftDataSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
      </CardContainer>
      <CardContainer>
        <Card minHeight="400px">
          <CardTitle>Amount</CardTitle>
          {(!isAcceleratedPayoutDataFetching && (
            <ReactApexChart options={lineChartOptions(CurrencyFormatter, [COLOR_VENDOR])} series={openAmountDataSeries} type="line" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
        <Card minHeight="400px">
          <CardTitle>Amount (Periodic)</CardTitle>
          {(!isAcceleratedPayoutDataFetching && (
            <ReactApexChart options={barChartOptions(CurrencyFormatter, [COLOR_VENDOR])} series={periodicOpenAmountDataSeries} type="bar" height={350} />
          )) || <LoadingSpinner height='365px' />}
        </Card>
      </CardContainer>
    </StatisticsPageContainer>
  );
}

export default AcceleratedPayoutStatisticsPage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

export type ClientsState = {
  searchTerm?: string;
  needsReview?: boolean;
  creditTier?: api.CreditTier;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
  externalClientMappingsSearchTerm?: string;
  externalClientMappingsPartnerId?: api.Partners;
  externalClientMappingsStatus?: api.ExternalClientStatus;
  externalClientMappingsTag?: api.ExternalClientTags;
  externalClientMappingsPage?: number;
  externalClientMappingsTotalPages?: number;
  externalClientMappingsLimit?: PaginationDropdownLimit;
  externalClientMappingsSortField?: string;
  externalClientMappingsSortDirection?: api.SortDirection;
  vendorClientRestrictionsSearchTerm?: string;
  vendorClientRestrictionsPartnerId?: api.Partners;
  vendorClientRestrictionsRestrictionType?: api.VendorClientRestrictionType;
  vendorClientRestrictionsPage?: number;
  vendorClientRestrictionsTotalPages?: number;
  vendorClientRestrictionsLimit?: PaginationDropdownLimit;
  vendorClientRestrictionsSortField?: string;
  vendorClientRestrictionsSortDirection?: api.SortDirection;
};

export const initialState: ClientsState = {
  searchTerm: undefined,
  needsReview: undefined,
  creditTier: undefined,
  page: 1,
  totalPages: undefined,
  limit: DefaultPaginationDropdownLimits[0],
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  externalClientMappingsSearchTerm: undefined,
  externalClientMappingsPartnerId: undefined,
  externalClientMappingsStatus: undefined,
  externalClientMappingsTag: undefined,
  externalClientMappingsPage: 1,
  externalClientMappingsTotalPages: undefined,
  externalClientMappingsLimit: DefaultPaginationDropdownLimits[0],
  externalClientMappingsSortField: undefined,
  externalClientMappingsSortDirection: api.SortDirection.ASCENDING,
  vendorClientRestrictionsSearchTerm: undefined,
  vendorClientRestrictionsPartnerId: undefined,
  vendorClientRestrictionsRestrictionType: undefined,
  vendorClientRestrictionsPage: undefined,
  vendorClientRestrictionsTotalPages: undefined,
  vendorClientRestrictionsLimit: DefaultPaginationDropdownLimits[0],
  vendorClientRestrictionsSortField: undefined,
  vendorClientRestrictionsSortDirection: api.SortDirection.DESCENDING,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    updateSearchTerm(state: ClientsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
      state.page = 1;
    },
    updateNeedsReview(state: ClientsState, action: PayloadAction<boolean | undefined>) {
      state.needsReview = action.payload;
      state.page = 1;
    },
    updateCreditTier(state: ClientsState, action: PayloadAction<api.CreditTier | undefined>) {
      state.creditTier = action.payload;
      state.page = 1;
    },
    updatePage(state: ClientsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: ClientsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: ClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: ClientsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: ClientsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateExternalClientMappingsSearchTerm(state: ClientsState, action: PayloadAction<string>) {
      state.externalClientMappingsSearchTerm = action.payload;
      state.externalClientMappingsPage = 1;
    },
    updateExternalClientMappingsPartnerId(state: ClientsState, action: PayloadAction<api.Partners | undefined>) {
      state.externalClientMappingsPartnerId = action.payload;
      state.externalClientMappingsPage = 1;
    },
    updateExternalClientMappingsStatus(state: ClientsState, action: PayloadAction<api.ExternalClientStatus | undefined>) {
      state.externalClientMappingsStatus = action.payload;
      state.externalClientMappingsPage = 1;
    },
    updateExternalClientMappingsTag(state: ClientsState, action: PayloadAction<api.ExternalClientTags | undefined>) {
      state.externalClientMappingsTag = action.payload;
      state.externalClientMappingsPage = 1;
    },
    updateExternalClientMappingsPage(state: ClientsState, action: PayloadAction<number>) {
      state.externalClientMappingsPage = action.payload;
    },
    updateExternalClientMappingsTotalPages(state: ClientsState, action: PayloadAction<number>) {
      state.externalClientMappingsTotalPages = action.payload;
    },
    updateExternalClientMappingsLimit(state: ClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.externalClientMappingsLimit = action.payload;
    },
    updateExternalClientMappingsSortField(state: ClientsState, action: PayloadAction<string>) {
      state.externalClientMappingsSortField = action.payload;
    },
    updateExternalClientMappingsSortDirection(state: ClientsState, action: PayloadAction<api.SortDirection>) {
      state.externalClientMappingsSortDirection = action.payload;
    },
    updateVendorClientRestrictionsSearchTerm(state: ClientsState, action: PayloadAction<string>) {
      state.vendorClientRestrictionsSearchTerm = action.payload;
      state.vendorClientRestrictionsPage = 1;
    },
    updateVendorClientRestrictionsPartnerId(state: ClientsState, action: PayloadAction<api.Partners | undefined>) {
      state.vendorClientRestrictionsPartnerId = action.payload;
      state.vendorClientRestrictionsPage = 1;
    },
    updateVendorClientRestrictionsRestrictionType(state: ClientsState, action: PayloadAction<api.VendorClientRestrictionType | undefined>) {
      state.vendorClientRestrictionsRestrictionType = action.payload;
      state.vendorClientRestrictionsPage = 1;
    },
    updateVendorClientRestrictionsPage(state: ClientsState, action: PayloadAction<number>) {
      state.vendorClientRestrictionsPage = action.payload;
    },
    updateVendorClientRestrictionsTotalPages(state: ClientsState, action: PayloadAction<number>) {
      state.vendorClientRestrictionsTotalPages = action.payload;
    },
    updateVendorClientRestrictionsLimit(state: ClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.vendorClientRestrictionsLimit = action.payload;
    },
    updateVendorClientRestrictionsSortField(state: ClientsState, action: PayloadAction<string>) {
      state.vendorClientRestrictionsSortField = action.payload;
    },
    updateVendorClientRestrictionsSortDirection(state: ClientsState, action: PayloadAction<api.SortDirection>) {
      state.vendorClientRestrictionsSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updateNeedsReview,
  updateCreditTier,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateExternalClientMappingsSearchTerm,
  updateExternalClientMappingsPartnerId,
  updateExternalClientMappingsStatus,
  updateExternalClientMappingsTag,
  updateExternalClientMappingsPage,
  updateExternalClientMappingsTotalPages,
  updateExternalClientMappingsLimit,
  updateExternalClientMappingsSortField,
  updateExternalClientMappingsSortDirection,
  updateVendorClientRestrictionsSearchTerm,
  updateVendorClientRestrictionsPartnerId,
  updateVendorClientRestrictionsRestrictionType,
  updateVendorClientRestrictionsPage,
  updateVendorClientRestrictionsTotalPages,
  updateVendorClientRestrictionsLimit,
  updateVendorClientRestrictionsSortField,
  updateVendorClientRestrictionsSortDirection,
} = clientsSlice.actions;
export default clientsSlice.reducer;
